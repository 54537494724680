import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { isLastPage } from "../paging";
import ResultItem from "./ResultItem.jsx";
import LoadMore from "./LoadMore.jsx";
import LoadingSpinner from "./LoadingSpinner.jsx";
import Pagination from "./Pagination.jsx";
import {
    RESULTS_DISPLAY_REFRESH,
    RESULTS_DISPLAY_APPEND
} from "../redux/resultsDisplay";

const SimpleResultList = ({
    results,
    resultsDisplayStrategy,
    totalResults,
    loading,
    isFirstLoad,
    nextPage,
    gotoPage,
    query,
    pagination,
    dictionary
}) => {
    const isLast = isLastPage(totalResults, query.perPage, query.page);
    const shouldShowNoResults = results.length === 0 && !isFirstLoad;
    const shouldShowPager = totalResults > query.perPage;

    function wrapperClass() {
        const isRefreshing =
            loading && resultsDisplayStrategy === RESULTS_DISPLAY_REFRESH;
        const isAppending =
            loading && resultsDisplayStrategy === RESULTS_DISPLAY_APPEND;
        return classnames("search-results search-results--simple", {
            "is-refreshing": isRefreshing,
            "is-appending": isAppending
        });
    }

    function resultsRange() {
        // Upper bound of visible results
        let ceiling = query.page * query.perPage;

        // Lower bound of visible results
        let floor = ceiling - query.perPage + 1;

        // make sure the ceiling does not exceed total results
        if (ceiling > totalResults) {
            ceiling = totalResults;
        }

        // if results are only one page, eschew the range entirely
        if (totalResults <= query.perPage) {
            return `${totalResults}`;
        }

        // if we're using load more, we want to show only
        // the ceiling of results displayed
        if (resultsDisplayStrategy === RESULTS_DISPLAY_APPEND) {
            return `${ceiling}`;
        }

        // otherwise, return the full range
        return `${floor} - ${ceiling}`;
    }

    function renderPagingControl() {
        if (pagination && !isLast) {
            return (
                <LoadMore
                    handleClick={nextPage}
                    dictionary={dictionary}
                    loading={loading}
                    showSpinner={false}
                    buttonClass="btn"
                />
            );
        }
    }

    function getAnchorLink(title) {
        return title[0].toUpperCase();
    }

    return (
        <section className={wrapperClass()}>
            <div className="search-results__items">
                <div className="content-list__default">
                    {loading && (
                        <div className="search-results__loader">
                            <LoadingSpinner />
                        </div>
                    )}
                    <ul className="content-list__list">
                        {shouldShowNoResults ? (
                            <p>No results found to match your query.</p>
                        ) : null}
                        {results.map(item => (
                            <li className="content-list__item" key={item.id}>
                                <a name={getAnchorLink(item.title)}></a>
                                <ResultItem {...item} />
                            </li>
                        ))}
                    </ul>
                </div>
                {shouldShowPager ? renderPagingControl() : null}
            </div>
        </section>
    );
};

const {
    arrayOf,
    string,
    object,
    func,
    bool,
    number,
    shape,
    oneOfType
} = PropTypes;

SimpleResultList.propTypes = {
    results: arrayOf(
        shape({
            id: oneOfType([number, string]),
            title: string.isRequired
        })
    ),
    resultsDisplayStrategy: number,
    totalResults: number.isRequired,
    loading: bool,
    isFirstLoad: bool,
    nextPage: func,
    gotoPage: func,
    changeSort: func,
    query: shape({
        q: string,
        sortBy: string,
        page: number,
        perPage: number,
        pageId: string
    }),
    pagination: bool,
    dictionary: object
};

export default SimpleResultList;
