import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import customSelect from "custom-select";
import moment from "moment";

export default function () {

  const lowerize = event =>
    Object.keys(event).reduce((acc, k) => {
      acc[k.charAt(0).toLowerCase() + k.substring(1)] = event[k];
      return acc;
    }, {});

  const formatEvents = (events) => events.map(event => lowerize(event));

  const formatEventTypeValue = eventType => eventType.toLowerCase().split(" ").join("");

  const createEventTypeList = events => {

    const arrayUniqueByKey = [...new Map(events.map(item => [item.eventType, item])).values()];
    return arrayUniqueByKey;

  };

  const createSelect = (events) => {
    const eventTypes = createEventTypeList(events);

    let selectOptionsTemplate = "";

    for (const eventType of eventTypes) {
      selectOptionsTemplate += `
        <option value=${formatEventTypeValue(eventType.eventType)} data-color=${eventType.backgroundColor}>${eventType.eventType}</option>
      `;
    }

    const selectMenu = `
      <label for="event-type-selector" class="visually-hidden">event type selector</label>
      <select id="event-type-selector" class="event-select js-event-select">
        <option value="all" data-color="#002E59">All Event Types</option>
        ${selectOptionsTemplate}
      </select>
  `;

    return selectMenu;

  };

  const styleOptions = (calendarEl, events) => {
    const options = calendarEl.querySelectorAll(".custom-select-option");

    options.forEach((option, i) => {
      i > 0
        ? option.style.backgroundColor = createEventTypeList(events)[i - 1].backgroundColor
        : option.style.backgroundColor = "#002E59";
    });
  };

  const updateSelectOpenerColor = (calendarEl, color) => calendarEl.querySelector(".custom-select-opener").style.backgroundColor = color;

  const createCalendar = (element, events) => {
    let value = "all";

    const createCustomSelect = () => {
      const customSelector = customSelect(".js-event-select", {
        openerClass: "custom-select-opener event-select-opener"
      })[0];

      styleOptions(element, events);

      element.querySelector(".custom-select-opener").addEventListener("focus", function () {
        document.querySelector("body").style.overflow = "hidden";
      });

      element.querySelector(".custom-select-opener").addEventListener("blur", function () {
        document.querySelector("body").style.overflow = "";
      });

      customSelector.select.addEventListener("change", function (e) {
        const selectedI = this.selectedIndex;
        const color = this.options[selectedI].dataset.color;

        updateSelectOpenerColor(element, color);
        value = this.value;

        calendar.refetchEvents();

      });
    };

    const addSelect = () => {
      if (element.querySelectorAll("select").length < 1) {
        // insert select menu in empty left side toolbar div
        const leftToolBar = element.querySelectorAll(".fc-toolbar-chunk")[0];
        leftToolBar.insertAdjacentHTML("beforeend", createSelect(events));

        createCustomSelect();
      }
    };

    const calendar = new Calendar(element, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
      initialView: "dayGridMonth",
      buttonText: {
        month: "Calendar",
        list: "List"
      },
      headerToolbar: {
        left: "",
        center: "prev title next",
        right: "listMonth dayGridMonth"
      },
      events: function (fetchInfo, successCallback, failureCallback) {
        const filteredEvents = value === "all"
          ? events
          : events.filter(event => formatEventTypeValue(event.eventType) === value);

        const formattedEvents = filteredEvents.map((event) => {
          const startDate = moment(event.start);
          const startMonth = startDate.get("month");
          const startDay = startDate.get("day");

          const endDate = moment(event.end);
          const endMonth = endDate.get("month");
          const endDay = endDate.get("day");

          if (startMonth === endMonth && startDay === endDay) {
            return event;
          }

          return {
            ...event,
            end: endDate.add(1, "days").format()
          };
        });

        successCallback(formattedEvents);
      },
      datesSet: function (dateInfo) {
        addSelect();
      },
      eventDidMount: function (arg) {
        arg.el.setAttribute("target", "_blank"); // new window - TESS-407
        arg.el.setAttribute("title", arg.event.title); // tooltip - TESS-407
      }
    });

    calendar.render();

    element.classList.add("event-calendar--grid");
    // listen for list button click
    element.querySelector(".fc-listMonth-button").addEventListener("click", (e) => {
      element.classList.add("event-calendar--list");
      element.classList.remove("event-calendar--grid");
    });
    // listen for Calendar button
    element.querySelector(".fc-dayGridMonth-button").addEventListener("click", (e) => {
      element.classList.add("event-calendar--grid");
      element.classList.remove("event-calendar--list");
      calendar.render(); // needed to fix the height calculations.
    });
  };

  const createCalendars = () => {
    document.querySelectorAll(".js-event-calendar").forEach(element => {
      const formattedEvents = formatEvents(JSON.parse(element.dataset.events).Events);
      createCalendar(element, formattedEvents);
    });
  };

  createCalendars();
}
