import PropTypes from "prop-types";
import React from "react";
import LoadingSpinner from "./LoadingSpinner.jsx";

export default class LoadMore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initiatedLoad: false
        };
    }

    onClick(event) {
        event.preventDefault();
        this.props.handleClick();
        this.setState({
            initiatedLoad: true
        });
    }

    render() {
        const { dictionary } = this.props;
        const shouldShowSpinner =
            this.props.showSpinner &&
            this.props.loading &&
            this.state.initiatedLoad;
        return (
            <div className="end-filtered-list__view-btn">
                <button
                    disabled={this.props.loading}
                    className="btn btn--primary"
                    type="button"
                    onMouseDown={e => this.onClick(e)}
                    onKeyUp={e => {
                        if (e.keyCode === 13 || e.keyCode === 32) {
                            e.preventDefault(e);
                        }
                    }}
                >
                    <span className="btn--primary__text">
                        {dictionary.loadMoreLabel}
                    </span>
                    {shouldShowSpinner ? <LoadingSpinner /> : null}
                    <span className="btn--primary__icon-box"></span>
                </button>
            </div>
        );
    }
}

const { func, shape, string, bool } = PropTypes;

LoadMore.propTypes = {
    handleClick: func.isRequired,
    loading: bool,
    buttonClass: string,
    showSpinner: bool,
    dictionary: shape({
        loadMoreLabel: string
    })
};

LoadMore.defaultProps = {
    buttonClass: "btn btn--primary"
};
