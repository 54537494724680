import $ from 'jquery';
import "./custom-vendor/jquery.matchHeight-min.js";

export class RelatedTaxonomyHeights {
  constructor($el) {
    this.$el = $el;
    this.$header = $el.find('.related-taxonomy__col-header');
    this.matchtheheights(event);
  }

  matchtheheights(event) {
    this.$header.matchHeight();
  }
}
