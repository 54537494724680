import "./polyfill";
import $ from "jquery";
import "picturefill";

import { ImageSlider } from "./endocrine/ImageSlider";
import { Citations } from "./endocrine/Citations";
import { ViewMore } from "./endocrine/ViewMore";
import { Account } from "./endocrine/Account";
import { Store } from "./endocrine/Store";
import { ViewLarger } from "./endocrine/ViewLarger";
import { HeaderSubscribe } from "./endocrine/HeaderSubscribe";

import { createApp } from "./endocrine/search/factory";
import {
  defaultFacetedSearchFactory,
  defaultDynamicContentFactory
} from "./endocrine/search";
import { PrimaryNav } from "./endocrine/navigation/PrimaryNav";
import GDPRBanner from "./endocrine/GDPRBanner";
import { ViewAllExpand } from "./endocrine/ViewAllExpand";
import { SecondaryNav } from "./endocrine/navigation/SecondaryNav";
import { DynamicBanner } from "./endocrine/DynamicBanner";
import { RelatedTaxonomyHeights } from "./endocrine/RelatedTaxonomyHeights";
import { BackToTop } from "./endocrine/BackToTop";
import { WrapAZ } from "./endocrine/WrapAZ";
import { ValidateForm } from "./endocrine/ValidateForm";
import { MagicNav } from "./endocrine/navigation/MagicNav";
import { Hamburger } from "./endocrine/navigation/Hamburger";
import { SimpleNavTrigger } from "./endocrine/navigation/SimpleNavTrigger";
import RichText from "./endocrine/richText.js";
import ThumbnailGallery from "./endocrine/thumbnailGallery.js";


import { Accordion } from "./endocrine/accordion.js";
[...document.querySelectorAll(".js-accordion")].forEach(Accordion);

import DatePicker from "./endocrine/DatePicker.js";
DatePicker();

import EventCalendar from "./endocrine/eventCalendar";
EventCalendar();

// view more text
if ($(".js-more-text").length) {
  const viewMore = $.makeArray($(".js-more-text")).map(el => {
    return new ViewMore($(el));
  });
}

// Account
if ($(".js-account").length) {
  const account = $.makeArray($(".js-account")).map(el => {
    return new Account($(el));
  });
}

//Store
if ($(".member-login__icon.js-account").length) {
  const store = $.makeArray($(".member-login__icon.js-account")).map(el => {
    return new Store();
  });
}

if ($(".js-overview-block").length) {
  const store = $.makeArray($(".js-overview-block")).map(el => {
    return new Store($(el));
  });
}

// header subscribe
if ($(".js-newsletter-signup").length) {
  const headerSubscribe = $.makeArray($(".js-newsletter-signup")).map(el => {
    return new HeaderSubscribe($(el));
  });
}

// GDPR Banner
$(".end-gdpr-banner").each((i, el) => {
  GDPRBanner(el);
});

$(document).ready(function() {
  // Hero Menu Carousel
  new ImageSlider($(".end-hero-menu__list"), {
    // dots: false,
    speed: 300,
    slidesToShow: 7,
    slidesToScroll: 7,
    infinite: false /* Infinite isn't WCAG compliant, turn off */,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 7
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // NOTE: images stop displaying upon shrinking viewport sizes
      // {
      //   breakpoint: 910,
      //     settings: {
      //       slidesToShow: 5,
      //       slidesToScroll: 5,
      //       speed: 300
      //     }
      // }
    ]
  });
});

RichText();
ThumbnailGallery();

// PrimaryNav
const $primaryNavEl = $(".js-primary-nav");
if ($primaryNavEl.length) {
  let primaryNav = new PrimaryNav($primaryNavEl);
}

// Hamburger
const $hamburger = $(".js-hamburger");
if ($hamburger.length) {
  let hamburger = new Hamburger($hamburger, false, true);
}

// SimpleNavTrigger
const $simplenav = $(".js-simple-nav");
if ($simplenav.length) {
  const simplenav = $.makeArray($(".js-simple-nav")).map(el => {
    return new SimpleNavTrigger($(el));
  });
}

// Global Nav
const $globalnav = $(".js-magic-nav");
if ($globalnav.length) {
  const globalnav = $.makeArray($globalnav).map(el => {
    return new MagicNav($(el));
  });
}

// Expandable Listings
const viewAllables = $.makeArray($(".js-view-all-expand")).map(el => {
  return new ViewAllExpand($(el));
});

// Slideshows
// do not intialize slideshows inside
// page editor
if (!$("body").hasClass("is-page-editor")) {
  $(".js-slider").each((i, el) => {
    return new ImageSlider($(el), {});
  });
  $(".js-slider-fixed").each((i, el) => {
    return new ImageSlider($(el), { adaptiveHeight: false });
  });
}

// Secondary Nav
if ($(".js-secondary-nav").length) {
  const secondaryNav = new SecondaryNav($(".js-secondary-nav"));
}

// Dynamic Banners (Hero and Page Banner)
const $banners = $(".js-dynamic-banner");
if ($banners.length) {
  const dynamicBanners = $.makeArray($banners).map(el => {
    return new DynamicBanner($(el));
  });
}

// Search Apps
// only init if results are included
if ($(".js-search-results").length) {
  const searchId = $(".js-search-results").data("searchId");
  const searchApp = createApp(defaultFacetedSearchFactory(searchId));
  searchApp();
}

if ($(".js-dynamic-results").length) {
  const searchId = $(".js-dynamic-results").data("searchId");
  const dynamicContentApp = createApp(defaultDynamicContentFactory(searchId));
  dynamicContentApp();
}

// citations
if ($(".js-citations").length) {
  const citations = $.makeArray($(".js-citations")).map(el => {
    return new Citations($(el));
  });
}

// related taxonomy heights
if ($(".related-taxonomy").length) {
  const relatedTaxonomyHeights = $.makeArray($(".related-taxonomy")).map(
    el => {
      return new RelatedTaxonomyHeights($(el));
    }
  );
}

// back to top button
if ($(".js-back-to-top").length) {
  const backToTop = $.makeArray($(".js-back-to-top")).map(el => {
    return new BackToTop($(el));
  });
}

// view images/infographics larger in a modal
if ($(".js-image-block-zoomed").length) {
  const viewLarger = $.makeArray($(".js-image-block-zoomed")).map(el => {
    return new ViewLarger($(el));
  });
}

// view more text
if ($(".js-more-text").length) {
  const viewMore = $.makeArray($(".js-more-text")).map(el => {
    return new ViewMore($(el));
  });
}


if (!$("body").hasClass("is-page-editor")) {
  // Wrap all instances of 'A-Z' in a span so they dont break to new lines
  const wrapAZ = $.makeArray($("a:contains('A-Z')")).map(el => {
    return new WrapAZ($(el));
  });
}

// back to top button
if ($(".js-validate-form").length) {
  const form = $.makeArray($(".js-validate-form")).map(el => {
    return new ValidateForm($(el));
  });
}

// Fixing issue with embedded components where copy/paste doesn't work in EE mode.
if ($("body").hasClass("is-page-editor")) {
  $(
    "span[contenteditable=true] span[contenteditable=false] span[contenteditable=true]"
  ).on("paste", function(e) {
    e.preventDefault();
    var text = e.originalEvent.clipboardData.getData("text");
    console.log(text);
    document.execCommand("inserttext", false, text);
  });
}

$(document).ready(function () { });
