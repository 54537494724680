import PropTypes from 'prop-types';
import React from "react";
import FacetGroup from "./FacetGroup.jsx";
import {closeFilters, setFiltersOpenDirection, manageContainerWhitespace} from "../filterSlider";

export default class FacetList extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setFiltersOpenDirection();
    manageContainerWhitespace();
  }

  clearAll() {
    this.props.handleClearAll();
  }

  hideFilters() {
    closeFilters(); // DOM ops
    this.props.hideMobileFacets(); // action creator
  }

  renderHideFilters() {
    return (
      <div className="search-facets__filter-close">
        <button type="button"
                className="btn--primary"
                onClick={ () => this.hideFilters() }>
            {this.props.title}
          <svg className="icon">
            <use xlinkHref="/assets/img/common/svg-sprite.svg#close"></use>
          </svg>
        </button>

      </div>
    );
  }

  renderFacetGroups() {
    return this.props.facets.map( (group, i) => {
      if (group.values.length === 0 && group.childGroups.length === 0) {
        return null;
      }
      const numOpenGroups = this.props.numOpenGroups;
      const isOpen = typeof numOpenGroups !== "undefined"
        && numOpenGroups !== null
        && i < numOpenGroups;
      return (<FacetGroup key={group.id}
                          handleFacetChange={this.props.handleFacetChange}
                          handleClearGroup={this.props.handleClearGroup}
                          isOpen={isOpen}
                          group={group}/>);
    });
  }

  renderClearAll() {
    return (
      <div className="search-facets__clear-all">
        <button type="button"
                onClick={() => this.clearAll() }>
          Clear Filters
        </button>
      </div>
    );
  }

  render() {
    const facetGroups = this.renderFacetGroups();
    return (
      <div>
        {this.props.mobileFacetsShown ? <h3>{this.props.title}</h3> : null }
        <div className="search-facets">
          {facetGroups}
          {this.renderClearAll()}
        </div>
      </div>
    );
  }
}

const { string, arrayOf, bool, shape, number, oneOfType, func } = PropTypes;
FacetList.propTypes = {
  title: string,
  mobileFiltersShown: bool,
  hideMobileFacets: func,
  facets: arrayOf(shape({ id: oneOfType([number, string]) })),
  numOpenGroups: number,
  handleFacetChange: func.isRequired,
  handleClearGroup: func.isRequired,
  handleClearAll: func.isRequired
};

FacetList.defaultProps = {
  facets: [],
  numOpenGroups: null
};
