import R from "ramda";
import React from "react";
import PropTypes from "prop-types";
import { facetGroupShape } from "../../dataShapes";
import Flyout from "../../../common/components/Flyout.jsx";
import CollapsePane from "../../../common/components/CollapsePane.jsx";
import MultiSelectList from "./MultiSelectList.jsx";
import { allSelectedFacets, selectedFacetsFromGroup } from "../../redux/facets";
import MediaQuery from "react-responsive";

// Create a lens using a groupId to point to the openGroups value
const groupLens = groupId => R.lensPath(["openGroups", groupId]);

// Given a state, open the group beloning to an id
const openGroup = R.curry((groupId, state) =>
    R.set(groupLens(groupId), true, state)
);

// Given a state, close the group belonging to an id
const closeGroup = R.curry((groupId, state) =>
    R.set(groupLens(groupId), false, state)
);

// Set all group ids to closed
const closeAllGroups = R.over(R.lensProp("openGroups"), v => false);

// Given a state, open a closed group or close an open group
const toggleGroup = R.curry((groupId, state) =>
    R.ifElse(
        R.compose(
            R.equals(true),
            R.view(groupLens(groupId))
        ),
        closeGroup(groupId),
        openGroup(groupId)
    )(state)
);

/** A horizontal bar of groupings containing selectable facets/filters */
class FilterSelectBar extends React.Component {
    constructor(props) {
        super(props);
        this.buttonRefs = {};
        this.state = {
            openGroups: {}
        };
    }

    handleGroupClick(event, groupId) {
        event.stopPropagation();

        if (this.isGroupOpen(groupId)) {
            this.setState(toggleGroup(groupId, this.state));
        } else {
            this.setState(
                R.pipe(
                    closeAllGroups,
                    openGroup(groupId)
                )(this.state)
            );
        }
    }

    handleCloseGroup(groupId) {
        this.setState(closeGroup(groupId, this.state));
    }

    isGroupOpen(groupId) {
        return this.state.openGroups[groupId] || false;
    }

    renderList(group) {
        const { onFilterChange } = this.props;
        const flyoutIsInFlow = matchMedia("(max-width: 480px)").matches;
        const selectedFilters = selectedFacetsFromGroup(group);
        const hasSelected = selectedFilters.length > 0;

        return (
            <Flyout
                open={this.isGroupOpen(group.id)}
                doNotCloseOn={[this.buttonRefs[group.id]]}
                anchorToEl={() => this.buttonRefs[group.id]}
                inFlow={flyoutIsInFlow}
                requestClose={() => this.handleCloseGroup(group.id)}
            >
                <div className="filter-select-bar__list-top">
                    <div className="filter-select-bar__list-labels">
                        {this.props.showSelected && hasSelected > 0 && (
                            <span className="filter-select-bar__list-label">
                                Selected
                            </span>
                        )}
                        <button
                            className="filter-select-bar__clear-group"
                            onClick={this.props.handleClearGroup(group)}
                            disabled={!hasSelected}
                        >
                            {this.props.dictionary.clearAll}
                        </button>
                    </div>
                    {this.props.showSelected && hasSelected > 0 && (
                        <MultiSelectList
                            items={selectedFilters}
                            showChildren={false}
                            onChange={this.props.onFilterChange}
                            onClear={this.props.handleClearGroup}
                        />
                    )}
                </div>
                <MultiSelectList
                    items={group.values}
                    groups={group.childGroups}
                    hideSelected={true}
                    onChange={this.props.onFilterChange}
                    onClear={this.props.handleClearGroup}
                />
            </Flyout>
        );
    }

    renderGroups(filterGroups) {
        const openClass = groupId =>
            this.isGroupOpen(groupId) ? "is-open" : "";

        return (
            <ul className="filter-select-bar__group-list">
                {filterGroups.map((g, i) => {
                    const numSelected = selectedFacetsFromGroup(g).length;
                    const selectedPostfix =
                        numSelected > 0 ? `(${numSelected})` : "";
                    return (
                        <React.Fragment key={i}>
                            <li
                                className={`filter-select-bar__group ${openClass(
                                    g.id
                                )}`}
                            >
                                <button
                                    id={`filter-select-bar__group-button__${g.id}`}
                                    type="button"
                                    ref={el => (this.buttonRefs[g.id] = el)}
                                    onClick={e =>
                                        this.handleGroupClick(e, g.id)
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={this.isGroupOpen(g.id)}
                                    disabled={g.values.length == 0}
                                >
                                    <span className="filter-select-bar__group-label">
                                        {`${g.label} ${selectedPostfix}`}
                                    </span>
                                    <span className="icon" />
                                </button>
                                {g.values.length > 0 ? this.renderList(g) : ""}
                            </li>
                        </React.Fragment>
                    );
                })}
            </ul>
        );
    }

    render() {
        const { dictionary, filterGroups, onFilterChange } = this.props;
        const title = dictionary.filterTitle;
        const allSelected = allSelectedFacets(filterGroups);
        const allSelectedLabel = `${allSelected.length} Selected`;

        return (
            <React.Fragment>
                {filterGroups.length > 0 && (
                    <section>
                        <div className="header">
                            <div className="header__heading">
                                {title && (
                                    <h6 className="filter-select-bar__title">
                                        {title}
                                    </h6>
                                )}
                            </div>
                        </div>
                        <div className="end-filtered-list__selection-menu">
                            {this.renderGroups(filterGroups)}
                        </div>
                    </section>
                )}
            </React.Fragment>
        );
    }
}

const { string, arrayOf, func, bool, shape } = PropTypes;

FilterSelectBar.propTypes = {
    filterGroups: arrayOf(facetGroupShape).isRequired,
    handleClearGroup: func.isRequired,
    dictionary: shape({
        filterTitle: string,
        clearAll: string
    }),
    showSelected: bool,
    title: string
};

FilterSelectBar.defaultProps = {
    dictionary: {
        filterTitle: "Filter By",
        clearAll: "Clear All"
    },
    showSelected: true
};

export default FilterSelectBar;
