// Note: object-fit has been refactored to be applied on-demand instead of automatically
// You may invoke it directly in your scripts

import "./es6-polyfill.js";
import "./closest.js";
import svg4everybody from "svg4everybody";
import "./popstate-event.js";
import { objectFitPolyfill } from "./object-fit.js";

if ("objectFit" in document.documentElement.style === false) {
    objectFitPolyfill();
}

svg4everybody();
