import Cookies from "js-cookie";

export default function createGDPRBanner(el) {
    const removeWidget = () => {
        document.querySelector("body").classList.remove("show-gdpr");
        el.parentNode.removeChild(el);
    };

    // show the banner if the user hasn't accepted it yet
    if (Cookies.get("widgetCookie" + el.getAttribute("id")) === undefined) {
        el.classList.remove("is-hidden");
        document.querySelector("body").classList.add("show-gdpr");
    } else {
        removeWidget();
    }

    // accept button handler
    el.querySelector(".js-accept-btn").addEventListener("click", e => {
        e.preventDefault();
        const widgetID = el.getAttribute("id");
        Cookies.set("widgetCookie" + widgetID, widgetID, { expires: 90 });
        removeWidget();
    });

    // close button handler
    el.querySelector(".js-close-btn").addEventListener("click", e => {
        e.preventDefault();
        removeWidget();
    });
}
