import {connect} from "react-redux";
import SearchBox from "../components/SearchBox.jsx";
import { hideMobileFacets, loadNewKeywords, showMobileFacets } from "../redux/search/search.actions";

const mapStateToProps = (state) => {
  return {
    keywords: state.query.q,
    dictionary: state.dictionary,
    loading: state.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleEntry: (value) => {
      dispatch(loadNewKeywords(value));
    },
    showMobileFacets: () => {
      dispatch(showMobileFacets());
    },
    hideMobileFacets: () => {
      dispatch(hideMobileFacets);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
