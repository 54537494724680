import { connect } from "react-redux";
import JumpLinks from "../components/JumpLinks.jsx";

const mapStateToProps = (state) => {
  return {
    results: state.results
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JumpLinks);
