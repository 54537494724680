import $ from 'jquery';

export class Account {
  constructor($el) {
    this.$el = $el;
    this.$button = this.$el.find('#cart-item-count');
    //todo
    var name = "cartCount";
    if (document.cookie.length > 0) {
      var cStart = document.cookie.indexOf(name + "=");
      if (cStart != -1) {
        cStart = cStart + name.length + 1;
        var cEnd = document.cookie.indexOf(";", cStart);
        if (cEnd == -1) {
          cEnd = document.cookie.length;
        }
        var count = unescape(document.cookie.substring(cStart, cEnd));
        this.$button.html(count);
      }
    }

  }
};
