import $ from "jquery";
import slick from "slick-slider";

export default function() {
    $(".js-thumbnail-gallery").each((i, el) => {
        const $el = $(el);
        const $mainSlider = $el.find(".js-thumbnail-gallery-main");
        const $thumbSlider = $el.find(".js-thumbnail-gallery-thumbs");

        $mainSlider
            .on({
                beforeChange: function(
                    event,
                    slick,
                    current_slide_index,
                    next_slide_index
                ) {
                    //remove all active class
                    $thumbSlider
                        .find(".slick-slide")
                        .removeClass("slick-main-active");
                    //set active class for current slide
                    $thumbSlider
                        .find(
                            ".slick-slide[data-slick-index=" +
                                next_slide_index +
                                "]"
                        )
                        .addClass("slick-main-active");
                }
            })
            .slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                asNavFor: ".js-thumbnail-gallery-thumbs",
                infinite: false
            });

        $thumbSlider.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: ".js-thumbnail-gallery-main",
            dots: false,
            focusOnSelect: true,
            infinite: false
        });

        $thumbSlider
            .find(".slick-slide")
            .first()
            .addClass("slick-main-active");
    });
}
