import $ from 'jquery';

export class ViewLarger {
  constructor($el) {
    this.$el = $el;
    this.$wrapper = this.$el.closest('.js-image-block');
    this.$button = this.$wrapper.find('.js-image-block-zoom button');
    this.$close = this.$el.find('.js-image-block-zoomed-close');
    this.$document = $(document);
    this.escapeKeyCode = 27;
    this.bindEvents();
  }

  bindEvents() {
    this.$button.on('click', (event) => this.grow(event));
    this.$close.on('click', (event) => this.shrink(event));
    this.$document.on('keydown', (event) => this.shrinkWithKeyboard(event));
  }

  grow(event) {
    event.preventDefault();
    this.$el
      .addClass('is-open')
      .attr('aria-expanded', true);
  }

  shrink(event) {
    event.preventDefault();
    this.$el
      .removeClass('is-open')
      .attr('aria-expanded', false);
  }

  shrinkWithKeyboard(event) {
    if (event.which === this.escapeKeyCode) {
      this.shrink(event);
    }
  }
}
