/** @module search/service */
import $ from "jquery";
import R from "ramda";
import { RESULTS_DISPLAY_APPEND, RESULTS_DISPLAY_REFRESH } from "./resultsDisplay";

/** @typedef SearchResultsShape
 * @property {Array<FacetGroupShape>} facets Lists of facet groups, their facet values and child groups. See [FacetGroupShape]{@link module:search/facets~FacetGroupShape}.
 * @property {Array<Object>} results List of individual search result items. Object shape may vary project-to-project
 * @property {Object} request The request parameters that were originally sent up to the service.
 * This is mostly useful for debugging purposes, but can be used to allow the server to change parameters on-the-fly
 * @property {number} request.page The page number of results being requested
 * @property {string} request.pageId A GUID corresponding to the Sitecore page where the search lives
 * @property {number} request.perPage The number of results to return in the requested page
 * @property {string} request.q Keyword terms to search for
 * @property {string} request.sortBy The value to sort the results by
 * @property {string} request.sortOrder The order in which to display the results
 * @property {number} totalResults The total number of available results across all pages
 */

const newResults = R.curry((newResults, oldResults, strategy) => {
  return strategy === RESULTS_DISPLAY_APPEND
    ? R.concat(oldResults, newResults)
    : newResults;
});


/**
 * Initiates a GET request to the search endpoint
 * @param {string} url The url to send the request to
 * @param {object} query The data for the GET request
 * @returns Promise
 */
export function fetchSearchResults(url, query) {
    return $.ajax({
        url: url,
        data: query
    });
}

const setActiveSorter = (sorters, sortBy) => R.pipe(
    R.map(R.assoc("isActive", false)),
    R.map(R.when(R.propEq("value", sortBy), R.assoc('isActive', true)))
)(sorters);


/**
 * Map a response from a web service to our state object
 * @param {object} response Response data from our web service
 * @param {object} state Our current state data
 * @returns {object} Our new state data
 */
export function mapResponseToState(response, state) {
  const request = response.request;
  const castToInt = R.curry(parseInt)(R.__, 10);
  return R.mergeDeepLeft({
      totalResults: parseInt(response.totalResults, 10),
      results: newResults(response.results, state.results, state.resultsDisplayStrategy),
      facets: response.facets,
      sorters: setActiveSorter(state.sorters, response.request.sortBy),
      query: {
          pageId: request.pageId,
          page: castToInt(request.page),
          perPage: castToInt(request.perPage),
          sortOrder: request.sortOrder,
          loadAllPages: false
      }
  }, state);
}


