import $ from 'jquery';
import validate from 'jquery-validation'

export class ValidateForm {
  constructor($el) {
    $el.validate({
      errorPlacement: function(error, element) {
        error.appendTo(element.parent());
      }
    });
  }
}
