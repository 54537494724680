import $ from 'jquery';

export class HeaderSubscribe {
    constructor($el) {
        this.$el = $el;
        this.$emailAddress = $el.find('.js-newsletter-signup-email');
        this.$button = $el.find('.js-newsletter-signup-btn');
        this.$newsletterFooter = $('.js-newsletter-subscribe');
        this.$emailAddressFooter = $('.js-newsletter-subscribe-email');
        this.bindEvents();
    }

    bindEvents() {
        this.$button.on('click', (event) => this.enterEmail(event));
    }

    enterEmail(event) {
        event.preventDefault();
        let inputEmail = this.$emailAddress.val();

        $('html, body').animate({
            scrollTop: (this.$newsletterFooter.offset().top)
        }, 500);

        this.$emailAddressFooter.val(inputEmail).focus();
    }
}