import PropTypes from 'prop-types';
import R from "ramda";
import React from "react";

const links = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

const isMatch = R.curry((l, r) => r.title.toUpperCase().startsWith(l));

function linkIsValid(link, results) {
  return R.findIndex(isMatch(link))(results) >= 0;
}

function formatLink(l) {
  return "#" + l;
}

const JumpLinks = ({results}) => {
  return (
    <div className="dynamic-content__az">
      <ul>
        { links.map((l, i) => {
          return (
            <li key={i}>
              { linkIsValid(l, results) ? 
              <a href={formatLink(l)}>{l}</a> :
              <span>{l}</span>}
            </li>
          )
        })}
      </ul>
    </div>
  );
};

const { shape, string, arrayOf } = PropTypes;

JumpLinks.propTypes = {
  results: arrayOf(shape({
    title: string.isRequired
  }))
};

JumpLinks.defaultProps = {
  results: []
};

export default JumpLinks;
