import $ from 'jquery';

export class WrapAZ {
  constructor($el) {
    this.$el = $el;
    this.wrap(event)
  }

  wrap(event) {
    this.$el.html(function(_, html) {
       return html.replace(/(A-Z)/g, '<span class="u-nobr">$1</span>');
    });
  }
}
