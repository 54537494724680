import $ from "jquery";

export class SimpleNavTrigger {

  constructor($el) {
    this.$el = $el;
    this.$target = this.$el.next();

    this.bindEvents();
  }

  bindEvents() {
    let thisTrigger = this;

    
    this.$el.on("click", function() {
      thisTrigger.$el.toggleClass("is-open");
      thisTrigger.$target.toggle();
    });
  }
}
