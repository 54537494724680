import $ from "jquery";
import { breakpoint } from "../breakpoint";

let throttler;
const keyCodes = {
  tab: 9,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  enter: 13,
  space: 32,
  escape: 27
};

export class MagicNav {

  constructor($el) {
    this.$el = $el;
    this.$triggerlinks = this.$el.find(".js-magic-nav__child-trigger");
    this.$childLinks = this.$triggerlinks.find("li a");
    this.$activeItem = null; // Current context
    this.magicshrinkingnav = this.$el.hasClass("js-shrinkable");

    this.bindEvents();

    if (this.magicshrinkingnav) {
      this.$more = this.$el.find(".js-magic-nav__more");
      this.$topLevelLinks = this.$el.find(".js-magic-nav__top-level-item:not(.js-magic-nav__more)");
      this.$moreLinks = this.$more.find(".js-magic-nav__sublevel-item");
      this.magicShrink();
    }
  }

  bindEvents() {
    let thisMenu = this;

    $(window).on("resize", event => {
      if (throttler) {
        window.clearTimeout(throttler);
      }
      throttler = setTimeout(() => {
        if (this.magicshrinkingnav) {
          thisMenu.magicShrink();
        }
      }, 50);
    });

    /* Key triggers for child links */
    /* Accessibile menus are expected to behave as application menus */
    this.$childLinks.on("keydown", function(e) {
      /* Prevent triggerlinks behavior from happening */
      e.stopPropagation();

      let $sublevelTarget = $(this).parents(".js-magic-nav__sublevel");
      let $toplevelTarget = $(this).parents(".js-magic-nav__top-level-item");
      let $itemParent = $(this).parents(".js-magic-nav__sublevel-item");

      switch (e.keyCode) {
        case keyCodes.tab:
          $sublevelTarget.hide().attr("aria-hidden", "true");
          $toplevelTarget.removeClass("is-open");
          $toplevelTarget.focus(); /* Focus parent, normal event flow will take us to the next one */
          break;
        case keyCodes.escape:
          e.preventDefault();
          $sublevelTarget.hide().attr("aria-hidden", "true");
          $toplevelTarget.removeClass("is-open");
          $toplevelTarget.focus(); /* Focus parent and stay there due to preventDefault */
          break;
        case keyCodes.down:
          e.preventDefault();
          let $nextTarget = $itemParent.next(":not(.is-hidden)").length ? $itemParent.next(":not(.is-hidden)").find("a") : $sublevelTarget.children("li:not(.is-hidden)").first().children("a");
          $nextTarget.focus();
          break;
        case keyCodes.up:
          let $prevTarget = $itemParent.prev(":not(.is-hidden)").length ? $itemParent.prev(":not(.is-hidden)").find("a") : $sublevelTarget.children("li:not(.is-hidden)").last().children("a");
          $prevTarget.focus();
          break;
        default:
      }
    });

    /* Click triggers for top-level links */
    thisMenu.$triggerlinks.on("click", function(e) {
      if (e.target === this)
      {
        e.preventDefault();
      }
      let $childTarget = $(this).children(".js-magic-nav__sublevel");
      let newHiddenState = $childTarget.attr("aria-hidden") === "true" ? "false" : "true";

      if (newHiddenState === "false") {
        let otherTriggers = thisMenu.$triggerlinks.not(this);
        otherTriggers.removeClass("is-open");
        otherTriggers.children(".js-magic-nav__sublevel").hide().attr("aria-hidden", "true");
      }

      $(this).toggleClass("is-open");
      $childTarget.toggle().attr("aria-hidden", newHiddenState);
    });

    /* Key triggers for top-level links */
    /* Accessibile menus are expected to behave as application menus */
    thisMenu.$triggerlinks.on("keydown", function(e) {

      let $childTarget = $(this).children(".js-magic-nav__sublevel");
      let $firstChildLink = $(this).children(".js-magic-nav__sublevel").children("li:not(.is-hidden)").first().children("a");
      let $lastChildLink = $(this).children(".js-magic-nav__sublevel").children("li:not(.is-hidden)").last().children("a");
      let newHiddenState = $childTarget.attr("aria-hidden") === "true" ? "false" : "true";

      switch (e.keyCode) {
        case keyCodes.tab:
          $childTarget.hide().attr("aria-hidden", "true");
          $(this).removeClass("is-open");
          break;
        case keyCodes.escape:
          if (newHiddenState === "true") {
            /* If we wouldn't be closing the thing anyway, let esc bubble to close any parents */
            /* Otherwise, if it's not closed, don't ALSO close container, modal, or other menus... */
            e.stopPropagation();
          }
          e.preventDefault();
          $childTarget.hide().attr("aria-hidden", "true");
          $(this).removeClass("is-open");
          break;
        case keyCodes.enter:
        case keyCodes.space:
          e.preventDefault();
          $childTarget.toggle().attr("aria-hidden", newHiddenState);
          $(this).toggleClass("is-open");
          break;
        case keyCodes.down:
          e.preventDefault();
          $childTarget.show().attr("aria-hidden", "false");
          $firstChildLink.focus();
          break;
        case keyCodes.up:
          e.preventDefault();
          $childTarget.show().attr("aria-hidden", "false");
          $lastChildLink.focus();
          break;
        default:
      }
    });
  }

  /*
   * magicShrink
   * Handles "More+" dropdown on Now and Administrative type pages.
   * Triggered if wrapping element has the class js-shrinkable
   * Note that the markup must ALSO support shrinkability, requiring a certain amount of link
   *   duplication.
   */
  magicShrink() {
    let targetWidth = this.$el.width();
    let filledSoFar = this.$more.outerWidth(true);
    let thisMenu = this;
    let dropFirstWidth = 1340; /* This is very much a magic number */

    /* Reset */
    this.$topLevelLinks.css({ display: "" });
    this.$moreLinks.css({ display: "none"}).addClass("is-hidden");

    this.$topLevelLinks.each(function(i) {
      let $moreCounterpart = $(thisMenu.$moreLinks.get($(this).index()));

      if (i === 0 && $(document).width() < dropFirstWidth) {
        /* Skip the first item for now */
      }
      else if (filledSoFar + $(this).outerWidth(true) > targetWidth) {
        $(this).css({ display: "none" });
        $moreCounterpart.css({ display: "block"}).removeClass("is-hidden");
        filledSoFar += $(this).outerWidth(true); /* We just want to stop, not squeeze in a later one */
      }
      else {
        $(this).css({ display: "" });
        $moreCounterpart.css({ display: "none"}).addClass("is-hidden");
        filledSoFar += $(this).outerWidth(true);
      }
    });

    /* This logic is a little strange due to the request to drop the first link first, then the last link, etc... */
    if ($(document).width() < dropFirstWidth && this.$topLevelLinks[0] && ((filledSoFar + $(this.$topLevelLinks[0]).outerWidth(true)) > targetWidth)) {
      $(this.$topLevelLinks[0]).css({ display: "none" });
    }
  }
}
