import DatePicker from "js-datepicker";

export default function() {
    const supportsDate = checkDateInput();

    [...document.querySelectorAll("input[type=date], .js-date-picker")].forEach(
        el => {
            if (supportsDate) {
                el.setAttribute("type", "date");
            } else {
                el.setAttribute("type", "text");
                const picker = initDatePicker(el);
            }
        }
    );

    function initDatePicker(el) {
        return DatePicker(el, {
            formatter: (input, date, instance) => {
                const value = formatDate(date);
                input.value = value; // => '01/01/2019'
            }
        });
    }

    function checkDateInput() {
        var input = document.createElement("input");
        input.setAttribute("type", "date");

        var notADateValue = "not-a-date";
        input.setAttribute("value", notADateValue);

        return input.value !== notADateValue;
    }

    function formatDate(date) {
        const d = new Date(date);

        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [month, day, year].join("/");
    }
}
