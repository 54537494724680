import PropTypes from 'prop-types';
import React from "react";

const SearchStatus = ({
  resultsRange,
  totalResults,
  keywords,
  selectedFacets,
  handleFacetDismiss,
  dictionary
}) => {

  const shouldRenderFacets = selectedFacets.length > 0;
  const shouldRenderKeywords = !!keywords;

  const onFacetClick = (value) => {
    handleFacetDismiss(value.id);
  };

  const resultLabel = totalResults === 1 ? "result" : "results";

  const renderSelectedFacets = () => {
    return (
      <div className="search-results__active-facets">
        <span>{dictionary.filtersLabel}</span>
        <ul>
          {selectedFacets.map( value => {
            return (
              <li key={value.id}>
                <span>{value.name}</span>
                <button onClick={() => onFacetClick(value)}>
                  <svg className="icon">
                    <use xlinkHref="/assets/img/common/svg-sprite.svg#close"></use>
                  </svg>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="search-results__status">
      <strong>{resultsRange}</strong>&nbsp;
      <span>of</span>&nbsp;
      <strong>{totalResults}</strong>&nbsp;
      <span>{resultLabel}</span>&nbsp;
      { shouldRenderKeywords && (
        <span>for &quot;{keywords}&quot;&nbsp;</span>
      )}
      { shouldRenderFacets && renderSelectedFacets() }
    </div>
  );

};

const { string, number, object, arrayOf, func } = PropTypes;

SearchStatus.propTypes = {
  resultsRange: string.isRequired,
  totalResults: number.isRequired,
  keywords: string,
  selectedFacets: arrayOf(object).isRequired,
  handleFacetDismiss: func.isRequired,
  dictionary: object
};

SearchStatus.defaultProps = {
  selectedFacets: []
};

export default SearchStatus;
