import $ from 'jquery';

export class ViewMore {
    constructor($el) {
        this.$el = $el;
        // this.$document = $(document);
        this.$button = this.$el.find('.js-more-text-btn button');
        this.$moreText = this.$el.find('.js-add-text');

        this.bindEvents();
    }

    bindEvents() {
        this.$button.on('click', (event) => this.more(event));
    }

    more(event) {
        event.preventDefault();
        this.$moreText.css('display', 'inline');
        this.$button.css('display', 'none');
    }
}