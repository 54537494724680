import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { isLastPage } from "../paging";
import { openFilters } from "../filterSlider";
import ResultItem from "./ResultItem.jsx";
import LoadMore from "./LoadMore.jsx";
import LoadingSpinner from "./LoadingSpinner.jsx";
import SortBar from "./SortBar.jsx";
import SearchStatus from "./SearchStatus.jsx";
import Pagination from "./Pagination.jsx";
import {
    RESULTS_DISPLAY_REFRESH,
    RESULTS_DISPLAY_APPEND
} from "../redux/resultsDisplay";

const SearchResultList = ({
    results,
    resultsDisplayStrategy,
    selectedFacets,
    totalResults,
    loading,
    isFirstLoad,
    sorters,
    nextPage,
    gotoPage,
    dismissFacet,
    changeSort,
    query,
    pagination,
    dictionary,
    showMobileFacets
}) => {
    const isLast = isLastPage(totalResults, query.perPage, query.page);
    const shouldShowNoResults = results.length === 0 && !isFirstLoad;
    const shouldShowPager = totalResults > query.perPage;

    function showFilters() {
        openFilters(); // DOM ops
        showMobileFacets(); // action creator
    }

    function wrapperClass() {
        const isRefreshing =
      loading && resultsDisplayStrategy === RESULTS_DISPLAY_REFRESH;
        const isAppending =
      loading && resultsDisplayStrategy === RESULTS_DISPLAY_APPEND;
        return classnames("search-results", {
            "is-refreshing": isRefreshing,
            "is-appending": isAppending
        });
    }

    function resultsRange() {
    // Upper bound of visible results
        let ceiling = query.page * query.perPage;

        // Lower bound of visible results
        let floor = ceiling - query.perPage + 1;

        // make sure the ceiling does not exceed total results
        if (ceiling > totalResults) {
            ceiling = totalResults;
        }

        // if results are only one page, eschew the range entirely
        if (totalResults <= query.perPage) {
            return `${totalResults}`;
        }

        // if we're using load more, we want to show only
        // the ceiling of results displayed
        if (resultsDisplayStrategy === RESULTS_DISPLAY_APPEND) {
            return `${ceiling}`;
        }

        // otherwise, return the full range
        return `${floor} - ${ceiling}`;
    }

    function renderPagingControl() {
        if (pagination) {
            return (
                <Pagination
                    totalResults={totalResults}
                    resultsPerPage={query.perPage}
                    currentPage={query.page}
                    handlePageChange={gotoPage}
                />
            );
        }

        if (!pagination && !isLast) {
            return (
                <LoadMore
                    handleClick={nextPage}
                    dictionary={dictionary}
                    loading={loading}
                />
            );
        }
    }

    function renderFilterToggle() {
        return (
            <button
                type="button"
                className="search-results__filter-open btn--primary"
                onClick={() => showFilters()}
            >
        Filter Your Results
                <svg className="icon">
                    <use xlinkHref="/assets/img/common/svg-sprite.svg#equalizer" />
                </svg>
            </button>
        );
    }

    return (
        <section className="end-filtered-list">
            <SearchStatus
                resultsRange={resultsRange()}
                totalResults={totalResults}
                keywords={query.q}
                selectedFacets={selectedFacets}
                handleFacetDismiss={dismissFacet}
                dictionary={dictionary}
            />
            <SortBar sorters={sorters} handleSortClick={changeSort} dictionary={dictionary} />
            <div className="search-results__item-wrapper">
                {loading && (
                    <div className="search-results__loader">
                        <LoadingSpinner />
                    </div>
                )}
                <div className="search-results__items">
                    {shouldShowNoResults ? (
                        <p>No results found to match your query.</p>
                    ) : null}
                    {results.map(item => <ResultItem key={item.id} {...item} />)}
                </div>
                <div className="end-filtered-list__view-btn">
                    <button type="submit" className="btn btn--primary">
                        <span className="btn--primary__text">View All</span>
                        <span className="btn--primary__icon-box">
                            <svg className="icon" role="img" aria-label="plus-sign">
                                <use xlinkHref="/assets/img/common/svg-sprite.svg#plus-sign"></use>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
            {shouldShowPager ? renderPagingControl() : null}
        </section>
    );
};

const {
    arrayOf,
    string,
    object,
    func,
    bool,
    number,
    shape,
    oneOfType
} = PropTypes;

SearchResultList.propTypes = {
    results: arrayOf(
        shape({
            id: oneOfType([number, string])
        })
    ),
    resultsDisplayStrategy: number,
    selectedFacets: arrayOf(object).isRequired,
    totalResults: number.isRequired,
    sorters: arrayOf(
        shape({
            id: oneOfType([number, string]).isRequired,
            label: string.isRequired,
            value: string.isRequired,
            direction: oneOfType([bool, string]),
            isActive: bool.isRequired
        })
    ),
    loading: bool,
    isFirstLoad: bool,
    nextPage: func,
    gotoPage: func,
    changeSort: func,
    showMobileFacets: func,
    dismissFacet: func,
    query: shape({
        q: string,
        sortBy: string,
        page: number,
        perPage: number,
        pageId: string
    }),
    pagination: bool,
    dictionary: object
};

SearchResultList.defaultProps = {
    selectedFacets: []
};

export default SearchResultList;
