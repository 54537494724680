import R from "ramda";
import * as RA from "ramda-adjunct";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

export default class ResultItem extends React.Component {
    constructor(props) {
        super(props);
        this.itemWrapper = null;
    }

    componentDidMount() {
        this.itemWrapper.className = this.itemWrapper.className + " is-visible";
    }

    formatDate(dateStr) {
        const date = new Date(dateStr);
        return moment(date).format("MMMM YYYY");
    }

    renderImage() {
        const { imageSrc, contentUrl, title, description } = this.props;
        if (imageSrc) {
            return (
                <figure className="end-filtered-item__media">
                    <a href={contentUrl} title={title}>
                        <img srcSet={imageSrc} />
                    </a>
                    <figcaption className="u-visuallyhidden">
                        {description}
                    </figcaption>
                </figure>
            );
        }
    }

    render() {
        const {
            title,
            contentUrl,
            primaryContentType,
            secondaryContentType,
			date,
            body,
            color,
			target
        } = this.props;

        const eyebrowStyle = color ? { backgroundColor: color } : null;

        return (
            <article
                className="end-filtered-item"
                ref={el => (this.itemWrapper = el)}
            >
                {this.renderImage()}
                <div className="end-filtered-item__content">
                    <header className="end-filtered-item__header">
                        {(primaryContentType || secondaryContentType) && (
                            <div className="end-filtered-item__eyebrow">
                                {primaryContentType && (
                                    <span
                                        className="end-filtered-item__category"
                                        style={eyebrowStyle}
                                    >
                                        {primaryContentType}
                                    </span>
                                )}
                                {secondaryContentType && (
                                    <span className="end-filtered-item__type">
                                        {secondaryContentType}
                                    </span>
                                )}								
                            </div>
                        )}
                        <h3
                            className="end-filtered-item__title"
                            id="{title}-a590c06a"
                        >
                            <a href={contentUrl} target={target}>{title}</a>
                        </h3>
						{date && (
							<p className="end-filtered-item__type">
								{date}
							</p>
						)}
                    </header>
                    <p className="end-filtered-item__description">{body}</p>
                </div>
            </article>
        );
    }
}

const { string } = PropTypes;
ResultItem.propTypes = {
    title: string.isRequired,
    body: string,
    contentUrl: string.isRequired,
    target: string,
    secondaryContentType: string,
    primaryContentType: string,
	date: string.isRequired,
    imageSrc: string,
    color: string
};
