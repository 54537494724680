import { connect } from "react-redux";
import FacetList from "../components/FacetList.jsx";
import {
  loadFacetChange,
  loadClearFacetGroup,
  loadClearAllFacets,
  hideMobileFacets
} from "../redux/search/search.actions";

const mapStateToProps = (state, ownProps) => {
  return {
    title: state.dictionary.facetsTitle,
    facets: state.facets,
    numOpenGroups: state.numOpenGroups,
    mobileFacetsShown: state.mobileFacetsShown
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleFacetChange: (facetId, facetValue) => {
      dispatch(loadFacetChange(facetId, facetValue));
    },

    handleClearGroup: (group) => {
      dispatch(loadClearFacetGroup(group));
    },

    handleClearAll: () => {
      dispatch(loadClearAllFacets());
    },

    hideMobileFacets: () => {
      dispatch(hideMobileFacets);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FacetList);
