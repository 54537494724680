export default function() {
    const d = document;

    [...d.querySelectorAll(".js-rich-text table")].forEach(table => {
        // console.log(table);
        const wrapper = d.createElement("div");
        wrapper.classList.add("rich-text__table-wrapper");
        table.parentNode.insertBefore(wrapper, table);
        wrapper.appendChild(table);
    });
}
