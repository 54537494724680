import $ from 'jquery';

export class BackToTop {
  constructor($el) {
    this.$el = $el;
    this.$htmlbody = $("html, body");
    this.bindEvents();
  }

  bindEvents() {
    // scroll to the top of the page when you click on the button
    this.$el.on("click", (event) => this.goToTop(event));
    $(window).on('scroll', (event) => this.showButton(event));
  }

  goToTop(event) {
    this.$htmlbody.animate({ scrollTop: "0" });
  }

  showButton(event) {
    var scrollPosition = $(event.target).scrollTop();
    var viewportHeight = $(window).height();
    // if you scrolled twice the distance of the height of your viewport ...
    // show the button
    if (scrollPosition >= viewportHeight) {
        $('.js-back-to-top').addClass("is-active");
    } else {
        $('.js-back-to-top').removeClass("is-active");
    }
  }
}
