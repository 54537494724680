/** @module search/initialState **/
import R from "ramda";
import RA from "ramda-adjunct";
import {
    RESULTS_DISPLAY_APPEND,
    RESULTS_DISPLAY_REFRESH
} from "./resultsDisplay";

/**
 * @typedef InitialState
 * @property {object} [dictionary={}] Key/value pairs corresponding to entries within a Sitecore dictionary item. Use as needed.
 * @property {string} [errorMsg=null] A place to store a current global error message than be used by componetns
 * @property {Array<FacetGroupShape>} [facets=[]] Array of FacetGroups in the current result set
 * @property {boolean} [isFirstLoad=true] Flag to denote if search results have been loaded once. Automatically set to false after the first request.
 * @property {boolean} [loading=false] Flag set if the app is currently waiting for a query to return
 * @property {boolean} [mobileFacetsShown=false] Flag for whether or not to trigger the display for facets on mobile
 * @property {number} [numOpenGroups=null]  Number of facets groups to show expanded on page load. Only used with accordion facet bar.
 * @property {boolean} [pagination=false] Controls whether or not to display the pagination control at the bottom of the result list. If false, a "Load More" button will appear instead.
 * @property {object} [preSelectedFacets={}] Mark facets as selected prior to initial search request. Used to restore facet state from url parameters. Specify using `{groupName: ["facetId1", "facetId2"]}`
 * @property {object} query Represents a search query, as sent to the server
 * @property {boolean} [query.loadAllPages=false] Flag to return all results up until the page being requested. Used in conjunction with the "Lore More" style of pagination when restoring state from the URL's query string.
 * @property {number} [query.page=1] The page number of results being requested
 * @property {string} [query.pageId=Empty GUID] A GUID corresponding to the Sitecore page where the search lives
 * @property {number} [query.perPage=10] The number of results to return in the requested page
 * @property {string} [query.q=null] Keyword terms to search for
 * @property {string} [query.sortBy="relevance"] The value to sort the results by
 * @property {string} [query.sortOrder="asc"] The order in which to display the results
 * @property {number} [resultsDisplayStrategy=RESULTS_DISPLAY_APPEND] Indicates how new result items should be displayed: either replacing the current result set or appending to it.
 * @property {Array<Object>} [sorters=[]] Array of sorter objects, which defines which sort options are available
 * @property {number} [totalResults=0] Total number of items in the current search result set
 * @property {string} [url="http:localhost:4000"] The enpoint url of the search web service
 */

/**
 * The starting state of the redux store, data to be shared among app components.
 * @type InitialState
 */
export const initialState = {
    dictionary: {},
    errorMsg: null,
    facets: [],
    isFirstLoad: true,
    loading: true,
    mobileFacetsShown: false,
    numOpenGroups: null,
    pagination: false,
    preSelectedFacets: {},
    query: {
        q: null,
        sortBy: "relevance",
        sortOrder: "asc",
        page: 1,
        perPage: 4,
        pageId: "00000000-0000-0000-0000-000000000000",
        loadAllPages: false
    },
    results: [],
    resultsDisplayStrategy: RESULTS_DISPLAY_APPEND,
    sorters: [],
    totalResults: 0,
    url: "http://localhost:4000"
};

// given a set of external config objects (e.g., window.threadSearchConfigs)
// find the appropriate config for the searchId and deep merge it into
// the initial state
export const applyExternalConfig = (configArr, searchId) => {
    if (!configArr) {
        console.warn(
            "Search: applyExternalConfig for initialState received a null or undefined config object. No changes applied."
        );
        return R.curry((obj1, obj2) => R.identity(obj2))({});
    }
    const config = R.find(R.propEq("searchId", searchId), configArr);
    return R.mergeDeepLeft(config);
};

// Change the key names of the initial state params,
// keyDef is object like: { oldName: "newName" }
export const applyRenameKeys = keyDef => RA.renameKeys(keydef);

// Given an array of property keys, remove them from the initial state
export const applyOmitKeys = keys => R.omit(keys);

// Based on the current value of "pagination", toggle the results display
// strategy ("append" (for no pagination) or "refresh" (for pagination))
export const applyResultDisplayStrategy = R.ifElse(
    R.propEq("pagination", true),
    R.assoc("resultsDisplayStrategy", RESULTS_DISPLAY_REFRESH),
    R.assoc("resultsDisplayStrategy", RESULTS_DISPLAY_APPEND)
);
