import $ from 'jquery';

export class Citations {
  constructor($el) {
    this.$el = $el;
    this.$button = $el.find('.js-citations-trigger');
    this.$expandTarget = $el.find('.js-citations-content');
    this.bindEvents();
  }

  bindEvents() {
    this.$button.on('click', (event) => this.expand(event));
  }

  expand(event) {
    event.preventDefault();

    if (this.$expandTarget.attr('aria-expanded') == "true" ) {
      this.$expandTarget
        .slideUp(200)
        .attr('aria-expanded', false);
      this.$el
        .removeClass("is-open")
        .addClass("is-closed")
        .removeClass("js-citations-trigger--bottom-round");
        // debugger;
    } else {
      this.$expandTarget
        .slideDown(200)
        .attr('aria-expanded', true);
      this.$el
        .removeClass("is-closed")
        .addClass("is-open")
        .addClass("js-citations-trigger--bottom-round");
    }
  }
}
