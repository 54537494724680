import $ from 'jquery';

export class Store {
  constructor($el) {
    if($el){
      this.$el = $el;
      this.$button = this.$el.find('#addToCartMain');
      this.bindEvents();
    }
    this.getCartCount()

  }

  bindEvents() {
    this.$button.on('click', (event) => this.addToCartClick(event));
  }




  addToCartClick(event) {
    event.preventDefault();
    var self= this;
    if ($(self).hasClass("disabled")) {
      return;
    }
    var key = self.$button.attr('data-key');

    if (key.length) {
      var count = parseInt($('#cartCount').val());
      //to do addToCart(key, count, this, true);
      try {
        $.ajax({
          type: "GET",
          crossDomain: true,
          url: 'https://ams.endocrine.org/' + avectraEnvironment + '/eWeb/OTI/WebServices/ShoppingCart.asmx/AddItem',
          contentType: "application/jsonp; charset=utf-8",
          data: { 'prd_key': key, 'quantity': count },
          dataType: "jsonp",
          success: function (msg) {
            if (msg.d > -1) {
              console.log("get chart count")
              self.getCartCount();
            }
          }
        }).fail(function () {
          //todo storeApi.updateCartCount("Unavailable");
        });
      } catch (e) {
        console.print(e);
        //todo storeApi.updateCartCount("Unavailable");
      }
    }
  }

  getCartCount() {
    try {
      var self= this;
      $.ajax({
        type: "GET",
        crossDomain: true,
        url: 'https://ams.endocrine.org/' + avectraEnvironment + '/eWeb/OTI/WebServices/ShoppingCart.asmx/GetCartCount',
        contentType: "application/jsonp; charset=utf-8",
        data: {},
        dataType: "jsonp",
        success: function (msg) {
          if (msg.d > -1) {
            self.writeCookie("cartCount", msg.d);
            self.updateCartCount(msg.d);
          }
        }
      }).fail(function () {
        self.updateCartCount("Unavailable");
      });
    } catch (e) {
      console.print(e);
      self.updateCartCount("Unavailable");
    }

   }

   writeCookie (name, value) {
     var date, expires;
    
     date = new Date();
     date.setMinutes(date.getMinutes() + 30);
     expires = "; expires=" + date.toGMTString();
    
     document.cookie = name + "=" + value + expires + "; path=/";
    }
    
    readCookie (name) {
     if (document.cookie.length > 0) {
       var cStart = document.cookie.indexOf(name + "=");
       if (cStart != -1) {
         cStart = cStart + name.length + 1;
         var cEnd = document.cookie.indexOf(";", cStart);
         if (cEnd == -1) {
           cEnd = document.cookie.length;
         }
         return unescape(document.cookie.substring(cStart, cEnd));
       }
     }
     return "";
    }

    updateCartCount  (count) {
 $('#cart-item-count').html(count);
 if (count == 1) {
   $('#cart-item-text').html("Item");
 } else {
   $('#cart-item-text').html("Items");
 }
}

};





//var velir = velir || {};

//velir.writeCookie = function (name, value) {
//  var date, expires;

//  date = new Date();
//  date.setMinutes(date.getMinutes() + 30);
//  expires = "; expires=" + date.toGMTString();

//  document.cookie = name + "=" + value + expires + "; path=/";
//};

//velir.readCookie = function (name) {
//  if (document.cookie.length > 0) {
//    var cStart = document.cookie.indexOf(name + "=");
//    if (cStart != -1) {
//      cStart = cStart + name.length + 1;
//      var cEnd = document.cookie.indexOf(";", cStart);
//      if (cEnd == -1) {
//        cEnd = document.cookie.length;
//      }
//      return unescape(document.cookie.substring(cStart, cEnd));
//    }
//  }
//  return "";
//};

//var storeApi = storeApi || {};
//storeApi.addToCart = function (key, count, button, popup) {
//  try {
//    $.ajax({
//      type: "GET",
//      crossDomain: true,
//      url: 'https://ams.endocrine.org/' + avectraEnvironment + '/eWeb/OTI/WebServices/ShoppingCart.asmx/AddItem',
//      contentType: "application/jsonp; charset=utf-8",
//      data: { 'prd_key': key, 'quantity': count },
//      dataType: "jsonp",
//      success: function (msg) {
//        if (msg.d > -1) {
//          storeApi.getCartCount();
//        }
//      }
//    }).fail(function () {
//      storeApi.updateCartCount("Unavailable");
//    });
//  } catch (e) {
//    console.print(e);
//    storeApi.updateCartCount("Unavailable");
//  }
//}

//storeApi.getCartCount = function () {
//  try {
//    $.ajax({
//      type: "GET",
//      crossDomain: true,
//      url: 'https://ams.endocrine.org/' + avectraEnvironment + '/eWeb/OTI/WebServices/ShoppingCart.asmx/GetCartCount',
//      contentType: "application/jsonp; charset=utf-8",
//      data: {},
//      dataType: "jsonp",
//      success: function (msg) {
//        if (msg.d > -1) {
//          velir.writeCookie("cartCount", msg.d);
//          storeApi.updateCartCount(msg.d);
//        }
//      }
//    }).fail(function () {
//      storeApi.updateCartCount("Unavailable");
//    });
//  } catch (e) {
//    console.print(e);
//    storeApi.updateCartCount("Unavailable");
//  }
//};


//storeApi.updateCartCount = function (count) {
//  $('#cart-item-count').html(count);
//  if (count == 1) {
//    $('#cart-item-text').html("Item");
//  } else {
//    $('#cart-item-text').html("Items");
//  }
//};





