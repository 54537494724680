import PropTypes from 'prop-types';
import React from "react";
import LoadingSpinner from "./LoadingSpinner.jsx";
import { closeFilters, openFilters } from "../filterSlider";

export default class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initiatedLoad: false,
      mobileFacetsOpen: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading) {
      this.setState({ initiatedLoad: false });
    }
  }

  showFilters() {
    openFilters(); // DOM ops
    this.props.showMobileFacets(); // action creator
    this.setState({
      mobileFacetsOpen: true
    });
  }

  hideFilters() {
    closeFilters(); // DOM ops
    this.props.hideMobileFacets(); // action creator
    this.setState({
      mobileFacetsOpen: false
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.handleEntry(this.keywordInput.value);
    this.setState({ initiatedLoad: true });
  }

  renderHideFilters() {
    return (
      <div className="search-facets__filter-close">
        <button type="button"
          className="btn--primary"
          onClick={ () => this.hideFilters() }>
          Filter Your Results
          <svg className="icon">
            <use xlinkHref="/assets/img/common/svg-sprite.svg#close"></use>
          </svg>
        </button>

      </div>
    );
  }

  renderShowFilters() {
    return (
      <div className="search-facets__filter-close">
        <button
          type="button"
          className="search-results__filter-open btn--primary"
          onClick={() => this.showFilters()}>
          Filter Your Results
          <svg className="icon">
            <use xlinkHref="/assets/img/common/svg-sprite.svg#equalizer" />
          </svg>
        </button>
      </div>
    );
  }

  render() {
    const { dictionary, keywords, loading } = this.props;
    const { mobileFacetsOpen } = this.state;
    const shouldShowSpinner = this.state.initiatedLoad && loading;
    return (
      <div>
        <form className="search-box" onSubmit={ e => this.handleSubmit(e) } noValidate>
          <div className="search-box__input">
            <label htmlFor="site-search" className="u-visuallyhidden">{dictionary.searchBoxLabel}</label>
            <input id="site-search"
              type="text"
              placeholder={dictionary.searchBoxPlaceholder}
              defaultValue={keywords || ""}
              ref={ input => this.keywordInput = input }
            />
            <div className="search-box__loader">
              { shouldShowSpinner ? <LoadingSpinner/> : null }
            </div>
            <button type="submit" className="search-box__button">
              <span className="u-visuallyhidden">Submit Search</span>
              <svg className="icon" role="img" aria-label="maginfying glass icon">
                <title>magnifying glass icon</title>
                <use xlinkHref="/assets/img/common/svg-sprite.svg#search"></use>
              </svg>
            </button>
          </div>
        </form>
        { !mobileFacetsOpen && this.renderShowFilters() }
        { mobileFacetsOpen && this.renderHideFilters() }
      </div>
    );
  }
}

const { string, func, shape, bool } = PropTypes;

SearchBox.propTypes = {
  keywords: string,
  handleEntry: func.isRequired,
  loading: bool,
  showMobileFacets: func.isRequired,
  hideMobileFacets: func.isRequired,
  dictionary: shape({
    searchBoxPlaceholder: string,
    searchBoxLabel: string
  }).isRequired
};
