/** @module search/components */

import React from "react";
import PropTypes from 'prop-types';
import R from "ramda";
import classnames from "classnames";
import {facetGroupShape, facetValueShape} from "../dataShapes";

import {
  valuesWithSelectedChildren,
  selectedFacetsFromGroup
} from "../redux/facets";

const MAX_FACETS_SHOWN = 5;

/**
 *
 * An accordion like section for a single grouping of facets
 * ```
 *  <FacetGroup
      group={group}
      isOpen={false}
      handleFacetChange={func}
      handleClearAll={func} />
 * ```
 * @component
 * @param {object} props
 * @param {bool} props.isOpen Flag for the open state of the accordion
 * @param {object} props.group The group of facets to show
 * @param {function} props.handleFacetChange Function called when a facet is selected/deselected
 * @params {function} prop.handleClearGroup Function called when clearing all selected facets
 */

class FacetGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGroupOpen: this.props.isOpen,
      isGroupExpanded: false,
      valuesExpanded: []
    };
  }

  // Lifecycle

  componentWillMount() {
    // if group is closed, but has selected
    // values, open it
    const group = this.props.group;

    const selected = selectedFacetsFromGroup(group);
    let shouldOpen = this.state.isGroupOpen;
    if (selected.length > 0) {
      shouldOpen = true;
    }

    // Some facets will be expanded by default due to
    // children being pre-selected
    const hasSelected = valuesWithSelectedChildren(group);
    const selectedIds = hasSelected.map( v => v.id);

    // similarly, if group's values or value children
    // are selected, auto-expand that group
    const tailValues = R.drop(MAX_FACETS_SHOWN, group.values);
    const shouldExpand = tailValues.reduce( (should, tv) => {
      if (tv.selected === true || selectedIds.indexOf(tv.id) >= 0) {
        should = true;
      }

      return should;

    }, false);


    this.setState({
      isGroupOpen: shouldOpen,
      isGroupExpanded: shouldExpand,
      valuesExpanded: selectedIds
    });
  }


  chunkValues(values) {
    // divide the values into two arrays, the first for the Top X facets
    // and the second for overflow. If recursion level is over zero, put
    // all values in first array.
    return values.length > MAX_FACETS_SHOWN
        ? R.splitAt((MAX_FACETS_SHOWN), values)
        : [values, []];
  }

  showExpandButton() {
    return !this.state.isGroupExpanded;
  }

  shouldRenderTailValues(tailValues) {
    return this.state.isGroupExpanded
      && tailValues.length > 0;
  }

  shouldRenderChildGroups(group) {
    return group.isOpen
      && !!group.childGroups
      && group.childGroups.length > 0;
  }

  toggleGroup() {
    const isOpen = !this.state.isGroupOpen;
    this.setState({ isGroupOpen: isOpen });
  }

  toggleExpand() {
    const isExpanded = !this.state.isGroupExpanded;
    this.setState({ isGroupExpanded: isExpanded });
  }

  toggleValueExpand(valueId) {
    // check if the valueId is in the list. Remove it
    // if found, add it if not.
    const valueIdx = this.state.valuesExpanded.indexOf(valueId);
    const selectedValues = valueIdx >= 0
      ? R.remove(valueIdx, 1, this.state.valuesExpanded)
      : R.append(valueId, this.state.valuesExpanded);

    this.setState({ valuesExpanded: selectedValues });
  }

  valueIsExpanded(value) {
    return this.state.valuesExpanded.indexOf(value.id) >= 0;
  }

  onFacetChange(event, valueId) {
    this.props.handleFacetChange(valueId, event.target.checked);
  }

  onClearGroup() {
    const group = this.props.group;
    this.props.handleClearGroup(group);
  }



  // Rendering
  renderOpenButton() {
      const openClass = classnames("search-facets__group-toggle", { "is-open": this.state.isGroupOpen });
      return (<button className={openClass} type="button" onClick={(e) => this.toggleGroup(e)}>
      </button>);
  }


  renderValueItem(value, recursionLevel) {
    const childValues = value.childValues;
    const hasChildValues = !!childValues && childValues.length > 0;
    const isExpanded = this.valueIsExpanded(value);
    const renderChildValues = hasChildValues && isExpanded;
    const toggleClass = classnames("search-facets__value-expand", { "is-open": isExpanded});

    return (
      <li key={value.id}>
        <input id={value.id} checked={value.selected}  onChange={ (e) => this.onFacetChange(e, value.id) } type="checkbox"/>
        <label htmlFor={value.id}>{value.name} ({value.count})</label>
        {hasChildValues && (
          <button type="button" className={toggleClass} onClick={ () => this.toggleValueExpand(value.id)}>
            <svg className="icon">
              <use xlinkHref="/assets/img/common/svg-sprite.svg#chevron-down"></use>
            </svg>
          </button>
        )}
        {renderChildValues && this.renderValues(childValues, recursionLevel + 1) }
      </li>
    );
  }

  renderFlatValues(values) {
    const renderValue = (value) => {
      return (
        <li key={value.id}>
          <input id={value.id} checked={value.selected}  onChange={ (e) => this.onFacetChange(e, value.id) } type="checkbox"/>
          <label htmlFor={value.id}>{value.name} ({value.count})</label>
        </li>
      );
    };

    return (
      <ul className="search-facets__group-values">
        { values.map( value => renderValue(value)) }
      </ul>
    );
  }

  renderValues(values, recursionLevel) {
    let chunkedValues;
    if (recursionLevel === 0){
      chunkedValues = this.chunkValues(values);
    } else {
      chunkedValues = [values, []];
    }

    const headValues = chunkedValues[0];
    const tailValues = chunkedValues[1];

    const stateClass = `search-facets__group-values is-level-${recursionLevel}`;
    const valuesWrapperClass = classnames("search-facets__values-wrapper", { "has-no-values": !values.length });
    const shouldRenderTailValues = this.shouldRenderTailValues(tailValues);

    return (
      <div className={valuesWrapperClass}>
        <ul className={stateClass}>
          {headValues.map( val => this.renderValueItem(val, recursionLevel) )}
        </ul>
        { shouldRenderTailValues && (
          <ul className={classnames(stateClass, "is-overflow")}>
            {tailValues.map( val => this.renderValueItem(val, recursionLevel) )}
          </ul>
        )}
      </div>
    );
  }

  renderChildGroups(group) {
    if (this.state.isGroupOpen
        && group.childGroups
        && group.childGroups.length) {

      return group.childGroups.map( childGroup => {
        if (childGroup.values.length === 0) {
          return null;
        }

        return (<FacetGroup key={childGroup.id}
                            group={childGroup}
                            dupeSelected={false}
                            handleFacetChange={this.props.handleFacetChange}
                            handleClearGroup={this.props.handleClearGroup}
        />);
      });
    }
  }

  render() {
    const group = this.props.group;
    const selectedValues = selectedFacetsFromGroup(group);
    const showDupeSelected = selectedValues.length > 0
      && this.props.dupeSelected;
    const groupClass = classnames("search-facets__group", {
      "is-open": this.state.isGroupOpen
    });
    const expandLabel = this.state.isGroupExpanded
      ? "Show Less"
      : "Show More";

    return (
      <div className={groupClass}>
        <div className="search-facets__group-heading">
          <h4>{group.label}</h4>
          {this.renderOpenButton()}
        </div>
          <div className="search-facets__group-wrapper">
          {this.state.isGroupOpen && (
            <div className="search-facets__group-body">
              {showDupeSelected && (
                <div className="search-facets__group-selected">
                  {this.renderFlatValues(selectedValues)}
                </div>
              )}
              {this.renderValues(group.values, 0)}
              {group.values.length > MAX_FACETS_SHOWN && (
                <div className="search-facets__group-more">
                  <button type="button" onClick={() => this.toggleExpand()}>{expandLabel}</button>
                </div>
              )}
              {this.renderChildGroups(group)}
              { group.values.length > 0 && (
                <div className="search-facets__group-bottom">
                  <button
                    onClick={ () => this.onClearGroup()}
                    className="search-facets__group-clear"
                    type="button">
                    Clear
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FacetGroup;

const { func, shape, bool, number, string, oneOfType, arrayOf } = PropTypes;

FacetGroup.propTypes = {
  isOpen: bool,
  dupeSelected: bool,
  handleFacetChange: func.isRequired,
  handleClearGroup: func.isRequired,
  group: facetGroupShape
};

FacetGroup.defaultProps = {
  isOpen: true,
  dupeSelected: true
};
